body {
  overflow-y: hidden;
}

@keyframes anim-in-1 {
    from, 15% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(1) {
    -webkit-animation: anim-in-1 1.5s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-1 1.5s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-1 1.5s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-2 {
    from, 18.85% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(2) {
    -webkit-animation: anim-in-2 1.55775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-2 1.55775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-2 1.55775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-3 {
    from, 22.7% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(3) {
    -webkit-animation: anim-in-3 1.6155s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-3 1.6155s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-3 1.6155s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-4 {
    from, 26.55% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(4) {
    -webkit-animation: anim-in-4 1.67325s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-4 1.67325s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-4 1.67325s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-5 {
    from, 30.4% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(5) {
    -webkit-animation: anim-in-5 1.731s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-5 1.731s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-5 1.731s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-6 {
    from, 34.25% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(6) {
    -webkit-animation: anim-in-6 1.78875s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-6 1.78875s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-6 1.78875s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-7 {
    from, 38.1% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(7) {
    -webkit-animation: anim-in-7 1.8465s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-7 1.8465s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-7 1.8465s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-8 {
    from, 41.95% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(8) {
    -webkit-animation: anim-in-8 1.90425s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-8 1.90425s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-8 1.90425s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-9 {
    from, 45.8% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(9) {
    -webkit-animation: anim-in-9 1.962s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-9 1.962s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-9 1.962s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-10 {
    from, 49.65% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(10) {
    -webkit-animation: anim-in-10 2.01975s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-10 2.01975s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-10 2.01975s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-11 {
    from, 53.5% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(11) {
    -webkit-animation: anim-in-11 2.0775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-11 2.0775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-11 2.0775s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-12 {
    from, 57.35% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(12) {
    -webkit-animation: anim-in-12 2.13525s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-12 2.13525s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-12 2.13525s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-13 {
    from, 61.2% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(13) {
    -webkit-animation: anim-in-13 2.193s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-13 2.193s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-13 2.193s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-14 {
    from, 65.05% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(14) {
    -webkit-animation: anim-in-14 2.25075s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-14 2.25075s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-14 2.25075s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-15 {
    from, 68.9% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(15) {
    -webkit-animation: anim-in-15 2.3085s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-15 2.3085s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-15 2.3085s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-16 {
    from, 72.75% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(16) {
    -webkit-animation: anim-in-16 2.36625s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-16 2.36625s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-16 2.36625s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-17 {
    from, 76.6% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(17) {
    -webkit-animation: anim-in-17 2.424s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-17 2.424s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-17 2.424s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-18 {
    from, 80.45% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(18) {
    -webkit-animation: anim-in-18 2.48175s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-18 2.48175s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-18 2.48175s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-19 {
    from, 84.3% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(19) {
    -webkit-animation: anim-in-19 2.5395s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-19 2.5395s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-19 2.5395s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-20 {
    from, 88.15% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(20) {
    -webkit-animation: anim-in-20 2.59725s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-20 2.59725s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-20 2.59725s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-21 {
    from, 92% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(21) {
    -webkit-animation: anim-in-21 2.655s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-21 2.655s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-21 2.655s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-22 {
    from, 95.85% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(22) {
    -webkit-animation: anim-in-22 2.71275s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-22 2.71275s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-22 2.71275s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-23 {
    from, 99.7% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(23) {
    -webkit-animation: anim-in-23 2.7705s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-23 2.7705s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-23 2.7705s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-24 {
    from, 103.55% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(24) {
    -webkit-animation: anim-in-24 2.82825s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-24 2.82825s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-24 2.82825s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-25 {
    from, 107.4% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(25) {
    -webkit-animation: anim-in-25 2.886s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-25 2.886s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-25 2.886s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}

@keyframes anim-in-26 {
    from, 111.25% {
        opacity: 0;
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
    }
}
.anim > *:nth-child(26) {
    -webkit-animation: anim-in-26 2.94375s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    -o-animation: anim-in-26 2.94375s cubic-bezier(0.18, 0.11, 0.27, 0.99);
    animation: anim-in-26 2.94375s cubic-bezier(0.18, 0.11, 0.27, 0.99);
}
