.content {
  height: 100vh;
}
.contentWrapper {
  margin: auto;
  display: flex;
  align-items: center;
  max-width: 1246px;
  min-height: 100vh;
}
.centeredContentBox {
  margin: auto;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  padding: 25px;
}
.QRCode {
  margin: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  border: 4px solid #202940;
  padding: 5px;
}
.twitchButton {
  background-color: #9147ff !important;
  color: #ffffff !important;
}
.paginationWrapper {
  margin-top: 25px;
  width: fit-content;
  margin: auto;
}
.shortenerHeadline {
  width: fit-content;
  font-weight: 900 !important;
  background: -webkit-linear-gradient(right, #56ccf2, #2f80ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.overflow {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shortenerTextfield > div {
  padding-right: 0px;
}
.shortnerButton {
  border-radius: 0px 4px 4px 0px !important;
  height: 56px;
}
.shortnerButtonEdit {
  border-radius: 0px 4px 4px 0px !important;
  height: 40px;
}
.shortenerButtonAction {
  border-radius: 4px !important;
  height: 40px;
}
.shortenerCheckbox {
  padding: 5px 0px 5px 15px !important;
}

.customShortText {
  display: inline-block;
  visibility: hidden;
  white-space: pre;
}

.newUrl {
  margin-top: 10px;
  width: 100%;
  border-radius: 4px !important;
}
.newUrlButton {
  border-radius: 4px !important;
}
.notFoundGif {
  margin-top: 25px !important;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  display:flex;
}
.notFoundGifWrapper {
  width: 100%;
  text-align: center;
}
.alignContents {
  display: contents !important;
  margin-right: 5px;
}

#hide {
  font: inherit;
  margin: 0;
  padding: 0;
}

#txt {
  border: none;
  color: #888;
  min-width: 10px;
}

#hide {
  position: absolute;
  height: 0;
  overflow: hidden;
  white-space: pre;
}
