.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.footer-container {
  margin-bottom: 10px;
}
.MuiLink-root {
  color: #ffffff !important;
}
